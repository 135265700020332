*, *::before, *::after {
    box-sizing: border-box;
  }

.actions-list {
    display: flex;
    align-items: center;
    gap: 25px;
    background-color: black;
    opacity: 0.65;
    color: #aaaaaa;
    padding: 15px;
    font-size: 11px;

}

.actions:first-child {
    gap: 0;
    margin-top: -5px;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

span {
    font-size: 22px;
    color: #00acc1;
    font-weight: 650;
}

.detail-card {
    width: 120px;
    height: 180px;
    background-color: #aaaaaa;
    margin: 40px auto;
}

.detail-poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.detailed-title {
    font-weight: 600;
    font-size: 16px;
}

.detail-options {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 10px;
}

.yellow {
    color: #ffd800
}

.servers {
    display: flex;
    flex-direction: column;
    gap: 10px ;
    margin-bottom: 30px;
}

.server {
    display: flex;
    gap: 20px;
    padding: 12px 15px 12px 20px;
    align-items: center;
    background-color: white;
    color: #111111;
    border-radius: 50px;
    font-size: 12px; 
    cursor: pointer;
}

.sub-server {
    display: flex;
    gap: 20px;
}

.weight {
    font-weight: 500;
}

.detailed-movie {
    margin: 25px;
    color: #ffffff;
    max-width: 1000px;
    padding-bottom: 50px;
}

.detailed-description {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 30px;
    gap: 10px;
    color: #dddddd;
    font-size: 10px;
}

.detailed-bottom-description,
.detailed-top-description,
.detailed-right-description,
.detailed-left-description {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.detailed-top-description {
    margin-bottom: 10px;
    gap: 10px;
}

.watch-methods {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;
    font-size: 10px;
}

.watch-method {
    background-color: #e2e6e8;
    color: #212529;
    padding: 5px;
    border-radius: 2px;
}

.line {
    background-color: #e2e6e8;
    height: 3px;
}



@media only screen and (min-width: 640px) {
    .HD {
     border: #a4988d 1px solid;
     border-radius: 3px;
     padding: 3px 7px;
    }
  
    .detailed-movie {
        display:  flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .detailed-title {
        font-size: 24px;
    }

    .detail-card {
        width: 180px;
        height: 266px;
        background-color: #aaaaaa;
        margin: 0 auto 40px 0;
    }

    .detail-options {
        gap: 30px;
        font-size: 12px;
    }

    .detailed-description {
        font-size: 12px;
    }

    .servers{
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }

    .server {
        padding-right: 35px;
    }

    .sub-server {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
    }

    .detailed-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .watch-methods {
        font-size: 12px;
    }

   }

   @media only screen and (min-width: 770px) {
    .detailed-bottom-description {
        flex-direction: row;
    }
   }

   @media only screen and (min-width: 1000px) {
        .detailed-movie {
            flex-direction: row;
            margin: 50px auto;
            padding: 0 20px 70px 20px;
            gap: 40px;
        }

        .detailed-section {
           width: 65%;
        }

        .detail-card {
            width: 240px;
            height: 355px;
            background-color: #aaaaaa;
            margin: 0 auto 40px 0;
        }
   }

   @media only screen and (min-width: 1300px) {
        .actions-list {
            background-color: #2c2c2e;
            position: absolute;
            flex-direction: column;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            top: 40%;
        }

        .detailed-movie {
            padding: 70px 0;
            margin-top: 0;
        }
   }

