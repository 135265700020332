*, *::before, *::after {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
.share-with {
    background-color: #2d323f;
    display: flex;
    align-items: center;
    
    flex-direction: column;
    gap: 30px;
    padding: 25px;
    border-radius: 4px;
    margin: 10px;
}

.share-with-text{
    color: #8e9585;
    font-size: 12px;
}

  .share-with-icons {
    display: flex;
    justify-content: center;
    gap: 20px; 
   
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #aaaaaa;
    width: 40px;
    height: 40px;
    padding: 10px;
    font-size: 22px;
  }

  .facebook-icon {
    background-color: #3b5998;
  }

  .twitter-icon {
    background-color: #1da1f2;
  }

  .telegram-icon {

    background-color: #ffffff;
    font-size: 43px;
  }

  .plus-icon {
    background-color: #ff6550;
  }

  @media only screen and (max-width: 640px) {
    .switch {
     display: none;
    }
   }

   @media only screen and (min-width: 1200px) {
    .switch {
     width: 1200px;
     align-self: center;
     margin: 0 auto;
    }
   }


  @media only screen and (min-width: 1300px) {
    .share-with {
        margin: 0;
        border-radius: 0;
        background: linear-gradient(to bottom, rgba(37, 42, 52, 1.0) 10%, transparent 100%);
    }

    .share-with-icons {
        flex-direction: column;
    }
    .switch {
        display: none;
    }
   }

   @media only screen and (max-width: 1300px) {
    .active {
        display: none;
    }
   }