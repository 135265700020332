*, *::before, *::after {
    box-sizing: border-box;
  }
  
  .footer {
    max-width: 780px;
    display: flex;
    flex-direction: column;
    margin: 60px auto 30px auto;
    gap: 10px;
  }

  .footer-top {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    gap: 20px;
    justify-content: center;
    padding: 0 30px;
    font-size: 14px;
    margin: 0;
  }

   .footer-links {
    margin: 0 5px;
  }

   .footer-bottom {
    margin: 0;
    display: flex;
    flex-direction: column;
    color: #888888;
    padding: 10px;
    font-size: 12px;
    line-height: 20px;
    gap: 20px;
}

.trademark {
    color: #8e95a5;
}