*, *::before, *::after {
    box-sizing: border-box;
  }
  

.trending {
    display: flex;
    flex-direction: column;
    align-items: flex-start ;
    margin: 30px 15px 30px 15px;
    gap: 20px;
    max-width: 1366px;
}

.section-title {
    border-left: 3px solid #00acc1ff;
    padding-left: 10px;
    font-size: 22px;
    font-weight: 600;

}

.space-break {
    display: flex;
    gap: 20px;
    margin-left: 5px;
}

.movies, 
.shows {
    display: flex;
    gap: 10px;
}

@media only screen and (min-width: 640px) {
    .trending {
        flex-direction: row;
        align-items: center;
        gap: 30px;
    }
   }