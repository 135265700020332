*, *::before, *::after {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .carousel {
    width: 100%;
    height: 250px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .carousel > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .slide {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: 200ms opacity ease-in-out;
    transition-delay: 200ms;
  }
  
  .slide-backdrop {
    display: block;
    width: 100%;
    height: 80%;
    object-fit:cover;
    object-position: top;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 10%, transparent 100%)
  }
  
  .slide[data-active] {
    opacity: 1;
    z-index: 1;
    transition-delay: 0ms;
    
  }
  
  .carousel-button {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 2;
    height: 44px;
    width: 27px;
    background: none;
    border: none;
    font-size: 3rem;
    font-weight: 700;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    cursor: pointer;
    border-radius: .25rem;
    padding: 0 .5rem;
    background-color: rgba(255, 255, 255, 0.200);
    outline: 1px solid white;
    visibility: show;
  }
  
  .carousel-button:hover,
  .carousel-button:focus {
    color: white;
    background-color: rgba(0, 0, 0, .2);
  }
  
  .carousel-button.prev {
    left: 1rem;
  }
  
  .carousel-button.next {
    right: 1rem;
  }

  .carousel-bottom-section {
    display: flex;
    position: absolute;
    align-items: flex-end;
    gap: 20px;
    margin-top: -15px;
    margin-left: 15px;
  }

  .carousel-img-card {
    position: relative;
    width: 120px;
    height: 180px;
    background-color: bisque;
    margin-left: 5px;
   
  }

  .carousel-play-icon {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 50px;
    border: solid 2px #00acc1ff;
    border-radius: 50%;
  }

  .carousel-movie-details {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    
  }
  .carousel-movie-title {
    font-weight: 600;
    font-size: 18px;
  }

  .carousel-movie-rating {
    display: flex;
    gap: 20px;
    font-size: 11px;
  }

  .carousel-movie-description {
    font-size: 11px;
    text-align: left;
    max-width: 580px;
    color: #aaaaaaff;
}

  .bold {
    font-weight: 700;
}
 
  @media only screen and (min-width: 480px) {
    .carousel {
     height: 300px;
    }
    .carousel-bottom-section {
        margin-top: -10px;
    }
   }

   @media only screen and (min-width: 720px) {
    .carousel {
     height: 400px;
    }
    .carousel-bottom-section {
        margin-top: -40px;
    }
   }

   @media only screen and (max-width: 720px) {
    .carousel-movie-description {
        display: none;
    }
   }

   @media only screen and (min-width: 1000px) {
    .carousel {
     height: 500px;
    }
    .carousel-bottom-section {
        margin-top: -110px;
    }
   }

   @media only screen and (max-width: 1000px) {

    .carousel-img-card {
        display: none;
    }

    .carousel-button {
        display: none;
    }
   }

   @media only screen and (min-width: 1200px) {
    .carousel-bottom-section {
        margin-top: -60px;
    }
   }