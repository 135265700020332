@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*, *::before, *::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.container {
  width: 100%;
}

.navbar {
  background-color: #2f3441ff;
  width: 100%;
  padding: 0px 20px 0 20px;
  display: flex;
  justify-content: center;
  
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  max-width: 1590px;

}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
  gap: 25px;
}

.nav-right {
  gap: 35px;
}

.left-logo {
  display: flex;
  gap: 40px;
}

.browse {
  background-color: #404757ff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  border-radius: 3px;
  height: 34px;
}

.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar .nav-container a:hover{
    font-weight: bolder;
}

.nav-container {
  display: flex;
  position: relative;
  height: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 21px;
  width: 21px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0.1;
  cursor: pointer;
}

.navbar .menu-items {
  padding-top: 100px;
  box-shadow: inset 0 0 2000px #0a0a0a;
  background-color: #0a0a0a;
  height: 100vh;
  width: 100%;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  padding-left: 40px;
  transition: transform 0.5s ease-in-out;
  text-align: left;
}

.navbar .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.logo {
  font-size: 1.2rem;
  color: #ffffff;
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0); 
}
  
  .nav-container input[type="checkbox"]:checked ~ .logo{
    display: none;
}

.flex {
  display:flex;
  gap: 10px;
  align-items: center;
}

.search-input {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 3px;
  width: 700px;
  height: 34px;
  gap: 5px;
  padding: 5px 10px;

}

input {
  border: none;
  width: 680px;
  height: 25px;
  outline: none;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: grey;
  padding-left: 5px;
}

.left-logo {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
 .left-logo {
  display: none;
 }  
}

@media only screen and (min-width: 640px) {
 .bar:first-child {
  display: none;
 }
 .browse {
  display: flex;
 }

 .logo1:first-child{
  display: none;
 }
}

@media only screen and (max-width: 1300px) {
  .search-input {
   display: none;
  }
 }

 @media only screen and (min-width: 1300px) {
  .nav-left {
   display: none;
  }

  .logo1:first-child{
    display: block;
   }
 }